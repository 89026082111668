import React from 'react';

import styled from 'styled-components';

import { GREEN, PALE_WHITE_2, WHITE, ACTION_TAG, ACTION_TAG_HOVER } from '../../appearance/Colors';

const ActionTagButton = ({ icon, label, type, ...rest }) => {
    return (
        <ActionTag {...rest}>
            {icon ? icon : null}
            {label}
        </ActionTag>
    )
};

const ActionTag = styled.div`
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${ACTION_TAG};
    border-radius: 8px;
    color: ${WHITE};
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
        background-color: ${ACTION_TAG_HOVER};
    }
`;

export default ActionTagButton;
