const environment = {
REACT_APP_CLOUD_NAME: 'djtncwjv1',
REACT_APP_UPLOAD_PRESET: 'oioovlwm',
REACT_MIXPANEL_TOKEN: '8ab40e3b3d36b3f38366602c71fd990a',
REACT_GOOGLE_ANALYTICS: 'UA-171693503-1',
REACT_FACEBOOK_PIXEL: '3049294228689093',
REACT_NODE_ENV: 'qa',
REACT_BACKEND_URL: 'https://qa.luckynote.io/graphql',
REACT_SUBSCRIPTION_URL: 'wss://qa.luckynote.io/graphql',
REACT_STRIPE_PUBLISHABLE_KEY: 'pk_test_LqsJMpLVp70JgQ0u4t2WTNKi00fmLbSdyF',
}
export default environment
