import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from "@apollo/react-hooks";
import { QUERY } from "../gql-operations";
import SettingsAdminComponent from "../components/SettingsAdminComponent";

function Admin() {
    const history = useHistory();

    // Check for token immediately
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            history.push('/');
        }
    }, [history]);

    const { loading, error, data } = useQuery(QUERY.ME, {
        onCompleted: (data) => {
            console.log('data', data)
            if (!data.me || data.me.role !== 'ADMIN') {
                history.push('/');
            }
        },
        onError: () => {
            console.log('error')
            history.push('/');
        }
    });

    // Show nothing while checking authentication
    if (!localStorage.getItem('token')) return null;
    if (loading) return null;
    if (error) return null;
    if (!data?.me || data.me.role !== 'ADMIN') return null;

    return (
        <SettingsAdminComponent />
    );
}

export default Admin;