import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';
import { FiCheck } from 'react-icons/fi';
import { GREEN, RED, WHITE } from '../../../../appearance/Colors';

import { Link } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import customAnalytics from '../../../../util/custom-analytics';
import SAVED_TYPE from '../../../../util/saved-from-enum';

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};

const Downloads = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: 'Download',
        paragraph: ``
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <section {...props} className={outerClasses} id="download">
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>
                        <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                            <div
                                className="tiles-item-inner"
                                style={{ backgroundColor: WHITE, cursor: 'pointer' }}
                                onClick={() => {
                                    customAnalytics.mobile_download(SAVED_TYPE.ANDROID);
                                    window.open(
                                        'https://play.google.com/store/apps/details?id=com.luckynote',
                                        '_blank'
                                    );
                                    // window.location.href = 'https://testflight.apple.com/join/qGrHfky5';
                                }}
                            >
                                <img
                                    src={require('../../../../assets/google-badge.png')}
                                    style={{ objectFit: 'contain', height: 100 }}
                                />
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div
                                className="tiles-item-inner"
                                style={{ backgroundColor: WHITE, cursor: 'pointer' }}
                                onClick={() => {
                                    customAnalytics.mobile_download(SAVED_TYPE.IOS);
                                    window.open('https://apps.apple.com/app/luckynotehq/id1566320984', '_blank');
                                }}
                            >
                                <img
                                    src={require('../../../../assets/ios-badge.png')}
                                    style={{ objectFit: 'contain', height: 100 }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Downloads.propTypes = propTypes;
Downloads.defaultProps = defaultProps;

export default Downloads;
