import React from 'react';

import Modal from './Modal';
import { Formik, yupToFormErrors, ErrorMessage } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';

import { MUTATION } from '../../gql-operations';

import useUpdateFolderDataOnTree from '../../hooks/useUpdateFolderDataOnTree';
import styled from 'styled-components';
import {
    PALE_WHITE_4,
    SECONDARY_COLOR,
    PALE_WHITE_6,
    PALE_WHITE_2,
    PALE_WHITE_3,
    WHITE,
    GREEN
} from '../../appearance/Colors';
import customAnalytics from '../../util/custom-analytics';

function UpdateFolderModal({ folder, isOpen, closeModal }) {
    const [updateFolderData] = useUpdateFolderDataOnTree();
    const [updateFolder] = useMutation(MUTATION.UPDATE_FOLDER, {
        onCompleted: async res => {
            console.log('you are complete', res);
            let updatedFolder = res.updateFolder;
            updateFolderData(updatedFolder);
            customAnalytics.folder_edit({
                'action': "Rename"
            })
        },
        onError: res => { }
    });

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={updateFolderModalStyle}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={updateFolderModalStyle.title}>
                    Rename<span style={{ color: WHITE }}> {folder.title}</span>
                </div>
                <Formik
                    initialValues={{ folderTitle: folder.title }}
                    onSubmit={async (values, actions) => {
                        updateFolder({
                            variables: {
                                data: {
                                    folderId: folder.id,
                                    title: values.folderTitle.trim()
                                }
                            }
                        });
                        closeModal();
                    }}
                    validationSchema={Yup.object().shape({
                        folderTitle: Yup.string().required('Required')
                    })}
                >
                    {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                        <div>
                            <form onSubmit={handleSubmit}>
                                <Input
                                    autoFocus
                                    name="folderTitle"
                                    type="text"
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    value={values.folderTitle}
                                    placeholder="Folder title"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            handleSubmit();
                                        }
                                    }}
                                />
                                <ErrorMessage
                                    name="folderTitle"
                                    render={msg => {
                                        console.log('msg', msg);
                                        // return <Text style={styles.errorMsg}>{msg}</Text>;
                                        return (
                                            <div id="folderTitle" className="error">
                                                {msg}
                                            </div>
                                        );
                                    }}
                                />
                                {/* <h3 style={{ flex: 1 }}>{folder.title} update me</h3> */}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: 16
                                    }}
                                >
                                    <span style={{ flex: 1 }}></span>
                                    <Button style={{ marginRight: 8 }} onClick={closeModal}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" style={{ backgroundColor: GREEN }}>
                                        Update
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

const updateFolderModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 10,
        border: 'none',
        maxWidth: 640,
        backgroundColor: '#222729'
    },
    title: {
        color: PALE_WHITE_6,
        fontWeight: 500,
        fontSize: 16,
        flex: 1,
        marginBottom: 16
    }
};

const Input = styled.input`
    padding-left: 10px;
    flex: 1px;
    color: ${WHITE};
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${PALE_WHITE_4};
    background-color: ${SECONDARY_COLOR};
    min-width: 250px;

    :hover {
        border: 1px solid ${PALE_WHITE_6};
    }

    :focus {
        border: 1px solid ${PALE_WHITE_6};
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${PALE_WHITE_4};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${PALE_WHITE_4};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${PALE_WHITE_4};
    }
`;

const Button = styled.button`
    border-radius: 8px;
    border: none;
    background-color: ${PALE_WHITE_2};
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border: 1px solid transparent;
    :focus {
        border: 1px solid ${PALE_WHITE_6};
    }
    &:hover {
        background-color: ${PALE_WHITE_3};
    }
`;

export default UpdateFolderModal;
